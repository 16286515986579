import React, { useEffect } from 'react';

import { useDispatch } from 'src/store/useDispatch';
import { useSelector } from 'react-redux';
import { fetchPresaleToken } from 'src/store/slice/presaleToken';

import { Loader } from 'src/components/Loader';
import PageHeader from 'src/components/PageHeader';

const PresaleTransactions = () => {
  const dispatch = useDispatch();
  const { presaleToken, loading } = useSelector(({ presaleToken }) => presaleToken);

  useEffect(() => {
    dispatch(fetchPresaleToken());
  }, []);

  if (loading) {
    return <Loader overlay />;
  }

  return (
    <>
      <PageHeader title="Presale Token" hideSearch />
      <div style={{ padding: '1.25rem' }}>
        <h2>Presale Token Info</h2>
        <section>
          <h3>Minimum Deposit</h3>
          <p>
            Amount: <strong>{presaleToken.minimum_deposit?.amount}</strong>
          </p>
          <p>
            Currency: <strong>{presaleToken.minimum_deposit?.currency}</strong>
          </p>
        </section>
        <section>
          <h3>General Info</h3>
          <p>
            Total Tokens: <strong>{presaleToken.total_tokens}</strong>
          </p>
          <p>
            Purchased Tokens: <strong>{presaleToken.purchased_tokens}</strong>
          </p>
          <p>
            Queued Tokens: <strong>{presaleToken.queued_tokens}</strong>
          </p>
          <p>
            Base Price: <strong>{presaleToken.base_price?.$numberDecimal}</strong>
          </p>
          <p>
            Current Stage: <strong>{presaleToken.current_stage}</strong>
          </p>
        </section>
        <section>
          <h3>Supported Payment Options</h3>
          {presaleToken.supported_payment_options?.map(
            (option: { blockchain: string; _id: string; deposit_address: string; supported_coins: string[] }) => (
              <div key={option._id} style={{ marginBottom: '0.75rem' }}>
                <p>
                  Blockchain: <strong>{option.blockchain}</strong>
                </p>
                <p>
                  Deposit Address: <strong>{option.deposit_address}</strong>
                </p>
                <p>
                  Supported Coins: <strong>{option.supported_coins.join(', ')}</strong>
                </p>
              </div>
            )
          )}
        </section>
        <section>
          <h3>Base Price for Each Stage</h3>
          {presaleToken.base_price_for_each_stage?.map(
            (stage: { stage: number; _id: string; triggering_amount: number; price_increment: number }) => (
              <div key={stage._id} style={{ marginBottom: '0.75rem' }}>
                <p>
                  Stage: <strong>{stage.stage}</strong>
                </p>
                <p>
                  Triggering Amount: <strong>{stage.triggering_amount}</strong>
                </p>
                <p>
                  Price Increment: <strong>{stage.price_increment}</strong>
                </p>
              </div>
            )
          )}
        </section>
        <section>
          <h3>Discounts</h3>
          {presaleToken.discounts?.map((discount: { _id: string; minimum_buy: string; discount: number }) => (
            <div key={discount._id} style={{ marginBottom: '0.75rem' }}>
              <p>
                Minimum Buy: <strong>{discount.minimum_buy}</strong>
              </p>
              <p>
                Discount: <strong>{discount.discount * 100}%</strong>
              </p>
            </div>
          ))}
        </section>
      </div>
    </>
  );
};

export default PresaleTransactions;
