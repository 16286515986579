import React, { useEffect, useState } from 'react';

import { useDispatch } from 'src/store/useDispatch';
import { useSelector } from 'react-redux';
import { fetchPresaleOrders, updateField, updateSearch } from 'src/store/slice/presaleOrders';

import { Loader } from 'src/components/Loader';
import { Table } from 'src/components/TableNew';
import PageHeader from 'src/components/PageHeader';
import NoResult from 'src/components/NoResult/NoResult';

import { PRESALE_ORDERS_HEADERS } from 'src/utils/constants';

const PresaleOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const {
    data: { presaleOrders, totalCount, totalPages, field, search },
    loading,
  } = useSelector(({ presaleOrders }) => presaleOrders);

  useEffect(() => {
    dispatch(fetchPresaleOrders({ page: currentPage, field, search }));
  }, [currentPage]);

  if (loading) {
    return <Loader overlay />;
  }

  return (
    <>
      <PageHeader
        title="Presale Orders"
        subtitle={`You have a total of ${totalCount} presale orders`}
        entity="presaleOrders"
        fetchEntity={fetchPresaleOrders}
        updateEntityField={updateField}
        updateEntitySearch={updateSearch}
        dropdownTitle={PRESALE_ORDERS_HEADERS[0]}
        dropDownItems={['Order No', 'Status', 'Blockchain', 'BXT Base Price', 'Payment Coin']}
      />
      {presaleOrders?.length ? (
        <Table
          headers={PRESALE_ORDERS_HEADERS}
          items={presaleOrders}
          columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
          detailsModalTitle="Order"
          fieldsToShow={[
            '_id',
            'order_number',
            'status',
            'blockchain',
            'bxlend_token_base_price',
            'payment_coin',
            'presale_stage',
            'tokens_allocation',
            'amount_in_usd',
          ]}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default PresaleOrders;
